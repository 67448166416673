<template lang="pug">
	section#filtros
		.wrapper
			p Filtro de eventos:
			div.filtros
				select(v-model="filtro.local_id", @change="emitLoad('local_id')")
					option(:value="null") Localização
					option(:value="local.id", v-for="local in locais", v-if="locais") {{local.nome}} - {{local.cidade_nome}}
				div.datas
					div.data
						span De:
						input(type="date", v-model="filtro.inicio", @change="emitLoad('inicio')")
					div.data
						span Até: 
						input(type="date", v-model="filtro.termino", @change="emitLoad('termino')") 
				div.busca
					input(type="text", v-model="filtro.q", @blur="emitLoad('q')", @keyup.enter="emitLoad('q')")
					button(@click="emitLoad('q')")
						img(:src="require('@images/icones/buscar.png')")
</template>

<script>

export default {
	data() {
		return {
			filtro: {
				local_id: null,
				inicio: null,
				termino: null,
				q: null
			},
			locais: null,
		}
	},
	created() {
		this.loadLocais()
	},
	methods: {
		loadLocais() {
			this.$axios
				.get(`api/eventos/locais`)
				.then(response => {
					this.locais = response.data;
				})
		},
		emitLoad(variavel) {
			let emit = {};
			emit[variavel] = this.filtro[variavel];
			emit.page = null;
			this.$eventsHandle.$emit('loadEventos', emit)
		}
	},
}
</script>

<style lang="stylus" scoped src="./Filtros.styl"></style>