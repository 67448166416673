<template lang="pug">
	section#eventosSec
		.wrapper
			ul
				li(v-for="evento, index in eventos")
					div.img
						img(:src="evento.listagem_thumb ? evento.listagem_thumb : require('@images/temp/imghome.png')", :style="evento.thumb_listagem && evento.thumb_listagem.size.width > evento.thumb_listagem.size.height ? cropHeigth : cropWidth")
					div.infoEventos
						h3 {{evento.titulo}}
						span {{!evento.online ? `${evento.local.cidade_nome} -` : ``}} {{evento.inicio}} a {{evento.termino}}
						p {{evento.descricao}}
						router-link(:to="{'name': 'evento', params:{'slug': evento.slug} }", v-if="!evento.online") Inscreva-se
						a(:href="evento.link_online", target="_blank", v-else) Inscreva-se
</template>

<script>

export default {
	components: {
	},
	props: {
		eventos: {
			type: Array,
		}
	},
	data() {
		return {
			cropHeigth: {
				maxHeight: "100%",
				width: "auto"
			},
			cropWidth: {
				maxWidth: "100%",
				height: "auto"
			}
		}
	},
}
</script>

<style lang="stylus" scoped src="./Eventos.styl"></style>