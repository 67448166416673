<template lang="pug">
	article#eventos
		Filtros
		Eventos(:eventos="eventos")
		button(v-if="paginacao.current_page < paginacao.last_page", @click="loadMore").carregar Carregar mais eventos
</template>

<script>
import Eventos from '@vue/sections/eventos/eventos/Eventos.vue'
import Filtros from '@vue/sections/eventos/filtros/Filtros.vue'
import qs from 'qs';

export default {
	components: {
		Eventos, 
		Filtros
	},
	created() {
		this.loadEventos()
		this.$eventsHandle.$on('loadEventos', this.loadEventos)
	},
	data() {
		return {
			paginacao: {},
			eventos: [],
			params: {
				limit: 4,
				local_id: null,
				inicio: null,
				termino: null,
				q: null,
				sort: null,
				page: null
			}
		}
	},
	methods: {
		loadEventos(params = null) {
			if (params)
				this.params = Object.assign({}, this.params, params)
			this.$axios
				.get(`api/eventos?${qs.stringify(this.params, { skipNulls: true })}`)
				.then(response => {
					let data = response.data.registros.data;
					this.paginacao = response.data.registros;
					if (this.params.page)
						data.forEach(evento => this.eventos.push(evento));
					else
						this.eventos = data;
				})
		},
		loadMore() {
			this.loadEventos({
				page: this.paginacao.current_page + 1
			})
		}
	},
}
</script>

<style lang="stylus" scoped src="./Eventos.styl"></style>